a:link {
    text-decoration: none;
    color: inherit;
}
  
a:visited {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

a:active {
    text-decoration: none;
    color: inherit;
}